import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesConsumerShared } from '../../../../../../../libs/env/src/index';
import { env as baseEnv } from '../env';

export const env: EnvironmentSalesConsumerShared = merge({}, baseEnv, {
  static: {
    expId: '2844',
    partnerId: '656',
    title: 'Zillow Insurance Services',
    eligibleProducts: ['homesite.home', 'homesite.renters'],
    personAddressPageIsLastIntroPage: true,
  },
  afiApiKey: '69230a56-41f0-42ec-a254-d6533448557d',
  afiAppname: 'zillow-monoline-renters',
  genesysChatDeploymentId: '345801fe-d298-4c34-8636-caa8bec82d94',
  genesysChatQueueName: 'HC_Zillow',
  genesysChatEnvironment: 'prod',
  chatEnabled: true,
  sapiClientId: 'hHvhxnEL4G8RVqrswuqHcc98W3ip3tCD',
  sapiClientSecret: 'Cy_gUEda2aC4bmZkpn9atqAC5UYmqCaF1odBVYEO2I-gXsoOksL4AiRwNF-U3OWZ',
});
